import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';

import ContactForm from '../../../components/forms/ContactForm';

import HeroH2 from '../../../components/hero/HeroH2';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H3 from '@rotaready/frecl/build/H3';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import Margin from '@rotaready/frecl/build/Margin';

const isBrowser = typeof window !== 'undefined';

const HeroWrapper = styled.div`
  background-repeat: no-repeat;

  background-position: center bottom;
  background-size: 100% auto;
  background-image: url(${props => props.backgroundMobile});

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    background-position: right top;
    background-size: auto 100%;
    background-image: url(${props => props.backgroundDesktop});
    transform: translate3d(0, -80px, 0);
  }
`;

const Hero = styled.div`
  padding: 80px 0 470px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 210px 0 130px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 40%;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 45%;
    margin: 0;
  }
`;

const TextBlock = styled.div`
  margin-bottom: 20px;
`;

const TileTagContainer = styled.div`
  margin-bottom: 20px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.formTopRef = React.createRef();
  }

  goToForm = () => {
    const scrollTo = this.formTopRef.current.offsetTop;

    if (isBrowser) {
      window.scroll({ top: scrollTo, left: 0, behavior: 'smooth' });
    }
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="The ultimate 2020 calendar for hospitality and retail - Rotaready"
          description="Seasonal events can massively affect footfall to your store or number of restaurant bookings. Here are some of the key dates to be aware of in 2020."
          url="resources/ultimate-2020-calendar-hospitality-retail"
        />

        <HeroWrapper
          backgroundDesktop={data.heroBackgroundDesktop.childImageSharp.fixed.src}
          backgroundMobile={data.heroBackgroundMobile.childImageSharp.fixed.src}
        >
          <ResponsiveContainer>
            <Hero>
              <HeroInnerContainer>
                <HeroBody>
                  <TileTagContainer>
                    <TileTag text="Hospitality" />
                    <TileTag text="Retail" />
                  </TileTagContainer>

                  <HeroH2 color="brand160" text="The ultimate 2020 calendar for hospitality and retail" />

                  <Margin marginTop={40}>
                    <div>
                      <Button uistyle="primary" text="Download now" size="lg" onClick={this.goToForm} />
                    </div>
                  </Margin>
                </HeroBody>
              </HeroInnerContainer>
            </Hero>
          </ResponsiveContainer>
        </HeroWrapper>

        <ResponsiveContainer>
          <BodyWrapper>
            <BodyItem>
              <TextBlock>
                <H3 uistyle="brand160" text="Whether you're running a retail or hospitality business there are a number of key dates you need to be aware of in 2020." />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="For pubs, bars and restaurants 2020 brings a whole host of sporting events, in addition to the recurring events such as St Patrick's day, Halloween and Christmas. And for the retailers amongst you, there's of course the likes of Black Friday and Cyber Monday, but have you considered how Valentine's Day, Easter or Thanksgiving could affect the footfall in your stores?" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Making sure you have the right number of staff on hand to meet demand throughout the year can play a big part in your success. So on that note, here's a list of the key dates to prepare for in 2020." />
              </TextBlock>
            </BodyItem>

            <BodyItem innerRef={this.formTopRef}>
              <ContactForm
                title=""
                submitLabel="Download now"
                source="resources_ultimate_2020_calendar"
                label="rotaready.com/resources/ultimate-2020-calendar-hospitality-retail"
                postTo="marketing"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Enjoy 🗓" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading our 'Ultimate 2020 Calendar for Hospitality and Retail'." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/Rotaready-Ultimate-2020-Calendar.pdf" download>
                        <Button uistyle="primary" text="View calendar" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </BodyItem>
          </BodyWrapper>
        </ResponsiveContainer>
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackgroundDesktop: file(relativePath: { eq: "resources/ultimate-2020-calendar-hospitality-retail/hero-desktop.png" }) {
      childImageSharp {
        fixed(height: 1400, width: 1512, quality: 30) {
          src
        }
      }
    }

    heroBackgroundMobile: file(relativePath: { eq: "resources/ultimate-2020-calendar-hospitality-retail/hero-mobile.png" }) {
      childImageSharp {
        fixed(height: 894, width: 750, quality: 30) {
          src
        }
      }
    }
  }
`;
